<template>
  <div class="detail">
    <div>
      <el-button class="" @click="handleBack">返回</el-button>
    </div>
    <!-- <img :src="newBaseurl + info.messageUrl" alt="" /> -->
    <div class="content">
      <div class="content-all">
         <h2>{{info && info.title}}</h2>
         <p class="date">发布时间: {{info && info.publishTime}}</p>
      </div>
      <div class="content-data">
        <p v-html="info && info.content"></p>
      </div>
   
     <!-- <div class="footer">
        <p class="next">下一篇:</p>
        <p class="last">上一篇:</p>
     </div> -->
    </div>
    <!-- <div>
      <h4>{{ info && info.messageTitle }}</h4>
      <p>{{ info && info.messageDate }}</p>
      <p>{{ info && info.messageSynopsis }}</p>
      <p v-html="info && info.messageData"></p>
    </div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      info: null
    }
  },
  created () {
    if (this.$route.query.allData) {
      this.info =(this.$route.query.allData)
    //   console.log(this.info)
    }
  },
  methods: {
    handleBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  width: 60%;
  height: 100%;
  margin:0 auto;
  border-radius: 5px;
  border-top:none;
  border-left:1px solid #f5f5f5;
  border-right:1px solid #f5f5f5;
  .content-all{
    width: 100%;
    height:23%;
    border:1px solid #F5F5F5;
    h2{
        margin-top:15px;
      padding-top:10px;
      text-align: center;
    }
    .date{
      text-align: center;
      color: #bbb;
      margin-top:6px;
      margin-bottom:6px;
    }
    
  }
  .content-data{
    width: 100%;
    height: 500px;
    overflow-y: auto;
    border-bottom: 1px solid #F5F5F5;
    padding:20px 20px 20px 20px;
    word-wrap: break-word;
    white-space: normal;
    word-break: break-all;
  }
  .footer{
    width:100%;
    height:40px;
    line-height: 40px;
   border-bottom: 1px solid #F5F5F5;
    // border-bottom: none;
    // border-left:none;
    // border-right:none;
    .next{
      float: left;
      margin-left:30px;
      font-size:12px;
    }
    .last{
      float:right;
      margin-right:30px;
      font-size:12px;
    }
  }
}
.detail > div:first-child {
  margin-bottom: 15px;
  text-align: right;
}
.detail img {
  width: 100%;
  height: 300px;
}
</style>